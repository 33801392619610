import React from "react";
import PrimaryButton from "../../components/PrimaryButton";

export const RegisterForm = (props) => {
  const [formData, setFormData] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    confirmEmail: "",
    password: "",
    confirmPassword: "",
    errorMessage: null,
  });

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.id]: event.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (formData.password !== formData.confirmPassword) {
      setFormData({
        ...formData,
        errorMessage: "Passwords do not match",
      });

      return false;
    }

    if (formData.email !== formData.confirmEmail) {
      setFormData({
        ...formData,
        errorMessage: "Emails do not match",
      });

      return false;
    }

    formData.email = formData.email.toLowerCase();

    props.onRegister(formData);
  };

  return (
    <div className="Register">
      <h2>Create an account</h2>
      <div>
        If you have an existing <strong>nobs guides™</strong> account, you can
        login with it!
      </div>
      <form className="form-signin mt-4">
        <div className="form-label-group">
          <input
            autoFocus
            id="firstName"
            className="form-control"
            placeholder="First Name"
            required
            value={formData.firstName}
            onChange={handleChange}
          />
        </div>
        <div className="form-label-group">
          <input
            id="lastName"
            className="form-control"
            placeholder="Last Name"
            required
            value={formData.lastName}
            onChange={handleChange}
          />
        </div>
        <div className="form-label-group">
          <input
            type="email"
            id="email"
            className="form-control"
            placeholder="Email"
            required
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div className="form-label-group">
          <input
            type="email"
            id="confirmEmail"
            className="form-control"
            placeholder="Confirm Email"
            required
            value={formData.confirmEmail}
            onChange={handleChange}
          />
        </div>
        <div className="form-label-group">
          <input
            type="password"
            id="password"
            className="form-control"
            placeholder="Password"
            required
            value={formData.password}
            onChange={handleChange}
          />
        </div>
        <div className="form-label-group">
          <input
            type="password"
            id="confirmPassword"
            className="form-control"
            placeholder="Confirm Password"
            required
            value={formData.confirmPassword}
            onChange={handleChange}
          />
        </div>
        <PrimaryButton
          label="Register"
          isFullWidth
          onClickAction={handleSubmit}
        />

        {formData.errorMessage && (
          <div className="error-message">{formData.errorMessage}</div>
        )}
        <br />
        <p className="agreement">
          By clicking <b>Register</b> you agree to our{" "}
          <a
            href="/terms-and-conditions"
            target="_blank"
            className="toggle-btn link-btn"
            rel="noopener noreferrer"
          >
            Terms & Conditions
          </a>{" "}
          and the{" "}
          <a
            href="/privacy-policy"
            target="_blank"
            className="toggle-btn link-btn"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
          .
        </p>
        <p>
          Need help? Visit{" "}
          <a
            className="link-btn"
            target="_blank"
            href="https://support.nobsapp.co.uk/hc/en-us"
            rel="noopener noreferrer"
          >
            Support Center
          </a>
        </p>
        <hr></hr>
      </form>
    </div>
  );
};

export default RegisterForm;
